<template>
  <div class="editContainer">
      <van-nav-bar
        title="编辑资料"
        left-arrow
        left-text="返回"
        @click-left="onClickLeft"
      />


    <div class="info-content">
      <van-cell-group  >
        <van-cell @click.stop.prevent="setAvatar" value-class="infos" title="头像" is-link center>
              <van-uploader >
                <img :src="avatarUrl" style="width:100%; height:50px; width:50px; border-radius:5px; margin-top:5px" alt="">
              </van-uploader >
        </van-cell>
        <van-cell  value-class="infos"  title="昵称" :value="nickname" is-link to="/SetName" center/>
      </van-cell-group>

      <van-field
        readonly
        clickable
        is-link
        input-align="right"
        :value="currentDate"
        label="生日"
        placeholder="请选择您的出生日期"
        @click="showTimePicker = true"
      />
      <van-popup v-model="showTimePicker" position="bottom">
        <van-datetime-picker
          type="date"
          
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onTimeConfirm"
          @cancel="showTimePicker = false"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        is-link
        input-align="right"
        name="picker"
        :value="value"
        label="性别"
        placeholder="点击选择性别"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>



    </div>

  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data() {
    return {
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(2022, 0, 1),
      currentDate: "",
      nickname: this.$store.getters.getUser.nickname,
      value: '',
      columns: ['男','女'],
      showPicker: false,
      showTimePicker: false,
      avatarUrl:""
    }
  },
  methods:{

    setAvatar(){
      this.$router.push("/setAvatar")
    },

    onConfirm(value) {
      //console.log(value)
      this.value = value;
      localStorage.setItem("sex",this.value)
      this.showPicker = false;
      Toast("更改成功")
    },

    onTimeConfirm(time) {
      //console.log(time)
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      this.currentDate  =  y + "-" + m + "-" + d ;
      localStorage.setItem("birthDate",this.currentDate)
      this.showTimePicker = false;
      Toast("更改成功")
    },
    
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }else{
        return `${val}日`;
      }
    },

    onClickLeft() {
      this.$router.push('/Main')
    },
  },
  created(){
 
    if(this.$store.getters.getUser.avatar){
        this.avatarUrl = this.$store.getters.getUser.avatar
    }else{
        this.avatarUrl = 'https://b.yzcdn.cn/vant/icon-demo-1126.png'
    }

    if(localStorage.getItem("birthDate")){
      // console.log(localStorage.getItem("birthDate"))
      this.currentDate = localStorage.getItem("birthDate")
    
    }
    if(localStorage.getItem("sex")){
      // console.log(localStorage.getItem("birthDate"))
      this.value = localStorage.getItem("sex")
      
    }
    

    this.hasLogin = true
    
  }
}
</script>

<style>

.editContainer{
  height: 100vw;
  background-color: #fafafa;
}

.top-button {
    background-color: #fafafa;
    width: 100%;
    height: 45px;
    position: relative;
    overflow: hidden;
    min-width: 320px;
    max-width: 640px;
    z-index: 20;
    
}

.back {
    position:absolute;
    top: 0;
    left:10px;
    width: 50px;
    height: 44px;
}

.back::before {
    content: "";
    display: block;
    width: 1.625rem;
    height: 1.625rem;
    background: url(../assets/images/back-black.png) no-repeat;
    background-size: 100%;
    margin:10px 20px 0px 0px;
}

.title{
    width: 50%;
    /* background-color: tomato; */
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10px;
    color: black;
    font-size: 17px;
}


.info-content {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.infos {
  color: rgb(83, 83, 83);
}


</style>